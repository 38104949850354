.terms-modal-content {
    p {
        padding-bottom: 20px;
    }

    h4 {
        font-weight: 600;
        font-size: 21px;
        line-height: 25px;
        margin-bottom: 20px
    }

    i {
        font-style: italic;
    }

    ul {
        li {
            &:last-of-type p {
                padding-bottom: 0;
            }
        }
    }

    hr {
        border: none;
        margin: 20px 0;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.12);

    }
}