.tax-details-btn {
  background: none !important;
  color: #007ec3;
  display: inline;
  padding: 0 10px;
  font-size: 14px;
}

.tax-details-btn:hover {
  background: none;
  color: #7ab800;
  text-decoration: underline;
}

.tax-details-btn svg {
  margin: 0px;
}

.inactive {
  transform: rotate(0deg);
  transition: all .2s linear;
}

.active {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all .2s linear;
}