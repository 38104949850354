.site-nav {
  background-color: #fff;
  padding: 20px;
  z-index: 100;
  position: relative;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.site-nav .nav-logo {
  width: 100%;
  max-width: 100px;
  height: auto
}

.site-nav a {
  display: block
}

.site-nav ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0
}

.site-nav ul,
.site-nav ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.site-nav ul .text-btn {
  font-size: 16px;
  font-weight: 500;
  color: #4d4f53;
  padding: 10px;
  text-transform: none
}

.site-nav ul .text-btn:hover {
  color: #7ab800
}

.site-nav.expand {
  padding-bottom: 0
}

.site-nav .menu-items-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.site-nav .menu-items-wrapper,
.site-nav .user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.site-nav .user-info {
  margin-left: 20px;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px
}

.site-nav .user-info img {
  width: 40px;
  height: auto;
  border-radius: 50%
}

.site-nav .user-info .user-info-text {
  display: none
}

.site-nav .user-info .user-name {
  font-weight: 700
}

.site-nav .mobile-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  position: relative
}

.site-nav .mobile-wrapper>li {
  margin: 0 6px
}

.site-nav .mobile-wrapper li {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.site-nav .mobile-wrapper li:hover>a,
.site-nav .mobile-wrapper li:hover>label,
.site-nav .mobile-wrapper li:hover>svg {
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  color: #7ab800;
  stroke: #7ab800
}

.site-nav .mobile-wrapper li label {
  cursor: pointer;
  position: relative
}

.site-nav .mobile-wrapper li label:focus {
  outline: none;
  color: #7ab800
}

.site-nav .mobile-wrapper li label svg {
  stroke: #707070;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  top: -2px
}

.site-nav .mobile-wrapper li .sub-menu label:after {
  padding-right: 14px
}

.site-nav .mobile-wrapper li i {
  font-size: 10px;
  margin-left: 5px
}

.site-nav .mobile-wrapper li a {
  padding: 10px 5px;
  color: #4d4f53;
  display: inline-block;
  width: 100%;
  -webkit-transition: color .2s ease;
  transition: color .2s ease
}

.site-nav .mobile-wrapper li input[type=checkbox] {
  display: none
}

.site-nav .mobile-wrapper li input:checked~label svg {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  stroke: #7ab800 !important;
  top: -2px
}

.site-nav .mobile-wrapper li input:checked~.has-submenu.sub-right .sub-menu {
  right: 0;
  left: inherit
}

.site-nav .mobile-wrapper li input:checked~.sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: inherit;
  right: inherit;
  opacity: 1;
  -webkit-transition: opacity .1s linear;
  transition: opacity .1s linear;
  overflow: visible
}

.site-nav .mobile-wrapper li input:checked~.sub-menu.sub-members-menu {
  right: 0
}

.site-nav .mobile-wrapper .sub-menu {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: absolute;
  background-color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  left: -99999px;
  top: 100%;
  opacity: 0;
  -webkit-transition: left 0s .1s, opacity .1s linear;
  transition: left 0s .1s, opacity .1s linear;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .18);
  box-shadow: 0 0 0 rgba(0, 0, 0, .18);
  overflow: hidden
}

.site-nav .mobile-wrapper .sub-menu li {
  padding: 0 20px;
  border-bottom: 1px solid rgba(154, 155, 156, .25);
  white-space: nowrap;
  width: 100%;
  text-align: center
}

.site-nav .mobile-wrapper .sub-menu li a {
  padding: 15px
}

.site-nav .mobile-wrapper .sub-menu li:last-child {
  border-bottom: 0
}

.site-nav .mobile-wrapper.app {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: -8px;
  position: relative
}

nav.app ul {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start
}

.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.name span {
  -ms-flex-item-align: center;
  align-self: center
}

.name img {
  width: 100px
}

.toggle {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding-top: 10px;
  place-content: flex-end;
  position: absolute;
  top: 20px;
  right: 10px
}

.toggle-ul {
  position: relative;
  top: -20px
}

.toggle img {
  -ms-flex-item-align: end;
  align-self: flex-end;
  width: 60px
}

.item.button {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3
}

.item {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  font-size: 20px;
  padding: 20px 0
}

.item,
.item:first-of-type {
  border-top: 1px solid #c4c4c4
}

.item.last {
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 40px
}

.cents,
.item.cent {
  text-align: -webkit-center
}

.active .item {
  display: block
}

.item,
.saveButton {
  display: none
}

.item {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  width: 100%;
  text-align: center
}

.item.first {
  margin-top: 20px
}

.main-content.app.white {
  background-color: #fff;
  padding-top: 0
}

.mpn {
  width: 100%;
  min-height: 100vh;
  text-align: center
}

.mpn li {
  border-bottom: 1px solid #d9dae4;
  padding: 24px
}

body {
  background-color: #fafafa
}

.background-wrapper {
  height: 100%;
  min-height: 100vh;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  position: relative
}

.background-wrapper,
.page {
  width: 100%;
  background-color: #fafafa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.page {
  margin: 0 auto;
  border-radius: 0;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  padding-bottom: 40px
}

.main-content,
.page {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.main-content {
  width: 100%;
  padding: 40px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative
}

.main-content>section:last-child {
  padding-bottom: 0
}

.intro-text {
  text-align: center
}

.intro-text h1:only-child {
  margin-bottom: 0
}

.intro-text p {
  font-size: 18px
}

.intro-text p:last-child {
  margin-bottom: 0
}

.step-progress-container {
  background: #fff;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: static;
  width: 100%
}

.step-progress-container .back-button,
.step-progress-container .next-button {
  position: fixed;
  bottom: 20px;
  z-index: 10
}

.step-progress-container .next-button {
  right: 20px
}

.step-progress-container .next-button:hover {
  text-decoration: none
}

.step-progress-container .back-button {
  left: 20px
}

.page-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: sticky;
  width: 100%;
  background: hsla(0, 0%, 98%, .6);
  background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fafafa), to(hsla(0, 0%, 98%, 0)));
  background: linear-gradient(0deg, #fafafa 20%, hsla(0, 0%, 98%, 0));
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  padding: 20px
}

.page-actions .back-button,
.page-actions .next-button {
  position: relative
}

.page-actions .next-button:hover {
  text-decoration: none
}

.main-content .space--between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto
}

.main-content .pos-relative {
  position: relative
}

.main-content p.bold {
  font-weight: 600
}

.main-content p.light {
  color: #9a9b9c
}

.logged-in .card.sponsor-card {
  padding: 0 20px 20px
}

.logged-in .card.sponsor-card .sponsor--id {
  padding-top: 15px
}

.ach-wrapper .address,
.credit-wrapper .address {
  margin-left: 38px
}

.mr-0 {
  margin-right: 0
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.italic {
  font-style: italic;
  font-weight: 400
}

.align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.align-top .vertical-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: space-around;
  -ms-flex-align: space-around;
  align-items: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.credits img {
  display: block;
  margin: 0 auto
}

.credits .ca-total {
  font-weight: 600;
  margin-left: 10px
}

.credits .quick-input {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 2px solid rgba(154, 155, 156, .18)
}

.credits .quick-input:last-of-type {
  border-bottom: 0
}

.credits .quick-input .input-group {
  max-width: 155px
}

.credits .quick-input .status-circle-s {
  margin-right: 5px
}

.credit-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px 40px;
  justify-items: start
}

.credit-grid,
.space--between {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.space--between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto
}

.green {
  color: #7ab800
}

.red {
  color: #ba0c2f
}

.dark-orange {
  color: #9a3b26
}

.dark-green {
  color: #427730
}

.font-20 {
  font-size: 20px
}

.font-18 {
  font-size: 18px
}

.font-14 {
  font-size: 14px
}

.w-max-content {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  width: auto
}

.flex-start-svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.flex-start-svg svg {
  margin-right: 10px
}

.content--footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.add-products .card .inner-content {
  padding-bottom: 0
}

.card .inner-content .product-quantity-card {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto;
  border: 3px solid transparent;
  -webkit-transition: all .2s ease;
  transition: all .2s ease
}

.card .inner-content .product-quantity-card.active {
  border: 3px solid #7ab800
}

.card .inner-content .product-quantity-card .product-info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px
}

.card .inner-content .product-quantity-card .product-info-wrapper img {
  width: 140px;
  height: 140px;
  -o-object-fit: cover;
  object-fit: cover;
  margin: 0;
  padding: 0
}

.card .inner-content .product-quantity-card .product-info-wrapper .product-info {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  margin-left: 20px
}

.card .inner-content .product-quantity-card .product-info-wrapper .product-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4d4f53;
  margin-bottom: 10px
}

.card .inner-content .product-quantity-card .product-info-wrapper ul {
  list-style: none;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2
}

.card .inner-content .product-quantity-card .product-info-wrapper ul li {
  color: #747679;
  line-height: 20px
}

.card .inner-content .product-quantity-card .product-info-wrapper .product-price {
  color: #4d4f53;
  font-size: 20px;
  line-height: 20px
}

.card .inner-content .product-quantity-card .card--footer {
  border-top: 1px solid #f2f2f2;
  margin: auto auto 0;
  width: 100%
}

.card .inner-content .product-quantity-card .card--footer.space--between span.dark-green,
.card .inner-content .product-quantity-card .card--footer.space--between span.gray {
  font-size: 14px;
  width: 120px
}

.card .inner-content .product-quantity-card .card--footer.space--between span.gray {
  color: rgba(154, 155, 156, .5)
}

.flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%
}

.total-cost {
  margin-left: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto
}

.button.blue.ghost {
  background-color: #fff
}

.button.blue.ghost:hover {
  background-color: #007ec3;
  border-color: #007ec3
}

p.sub-text {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 20px
}

.order-summary p.light {
  color: #9a9b9c
}

.order-summary p.bold {
  color: #4d4f53;
  font-weight: 600
}

.order-summary p.red {
  color: #ba0c2f
}

.order-summary .border-top-bottom {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed
}

.order-summary .double-border-bottom {
  border-bottom: 1px solid #ededed;
  -webkit-box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed;
  box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed
}

.order-summary .border-bottom {
  border-bottom: 1px solid #ededed
}

.order-summary .padding-10 {
  padding: 10px 0
}

.order-summary .total-review-summary p {
  margin-bottom: 0;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.order-summary .total-review-summary .review-row {
  padding: 20px 0
}

.order-summary .total-review-summary .review-row:last-of-type {
  padding: 10px 0;
  margin-bottom: 20px
}

.order-cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 0
}

.order-cell img {
  -o-object-fit: contain;
  object-fit: contain;
  height: 90px;
  width: 90px;
  margin-right: 15px
}

.order-cell .order-desc {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: grid;
  grid-gap: 5px
}

.order-cell .order-desc p {
  margin: 0
}

.order-cell .order-desc .product-title {
  margin-bottom: 5px;
  font-weight: 500
}

.order-cell .order-desc .product-cv {
  color: #9a9b9c;
  margin-bottom: 7px
}

.order-cell .order-desc .product-quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.order-cell .order-desc .product-price {
  text-align: left
}

.order-cell a {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content
}

.global-alert {
  bottom: 100%;
  position: absolute
}

.starter-kit-screen .main-content section.hide {
  padding-bottom: 0
}

.review-screen h1 {
  margin-bottom: 0
}

.review-screen .strike-through {
  color: #747679
}

.green-alert {
  margin: 20px auto 0
}

.table-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-bottom: 1px solid #ededed
}

.table-title p {
  margin-bottom: 10px;
  line-height: 1.2;
  font-weight: 700
}

.table-title a,
.table-title button {
  margin: -10px 0 0 10px;
  padding: 10px
}

.table-header {
  display: none;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 0;
  border-bottom: 1px solid #ededed;
  -webkit-box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed;
  box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed
}

.table-header p {
  line-height: 1;
  margin: 0 0 10px
}

.card .center p.address-country {
  margin-bottom: 20px
}

#section-account-address {
  margin-top: 20px
}

.modal .modal--starter-kit-wrapper .grid.tab-card .product--card,
.modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card {
  text-align: center
}

.modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20,
.modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .flex-20 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20>*,
.modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .flex-20>* {
  margin-right: 0
}

#confirm-address-modal .modal-content .center .blue--switch--container {
  min-width: 220px
}

.hide {
  height: 0;
  opacity: 0;
  -webkit-transition: visibility .2s linear, opacity .2s linear;
  transition: visibility .2s linear, opacity .2s linear;
  z-index: -2;
  visibility: hidden;
  position: absolute;
  left: -99999px;
  overflow: hidden
}

.hide.reveal,
.reveal {
  height: auto;
  opacity: 1;
  z-index: auto;
  visibility: visible;
  position: static;
  left: auto;
  overflow: initial
}

.info-tooltip-wrapper .info-button {
  background-color: #fafafa
}

@media (min-width:449px) {
  .card .inner-content .product-quantity-card .product-info-wrapper .product--card--content--inner {
    width: 140px
  }
}

@media (min-width:570px) {
  .order-cell img {
    height: 100px;
    width: 100px;
    margin-right: 20px
  }

  .order-cell .order-desc .product-quantity {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 5px
  }

  .order-cell .order-desc .product-price {
    margin-left: auto;
    text-align: right;
    font-size: 18px
  }

  .order-cell .order-desc {
    grid-template-columns: 1fr 1fr
  }

  .order-cell .order-desc .product-cv,
  .order-cell .order-desc .product-title {
    margin-bottom: 0
  }

  .order-cell .order-desc .product-title {
    -ms-flex-item-align: end;
    align-self: end
  }

  .order-cell .order-desc .product-cv {
    -ms-flex-item-align: start;
    align-self: start
  }

  .order-cell .order-desc {
    grid-template-columns: 4fr 2.5fr 3.5fr
  }

  .order-cell .order-desc .product-title {
    grid-row-start: 1;
    grid-row-end: 2
  }

  .order-cell .order-desc .product-price {
    grid-row-start: span 2;
    grid-column-start: 3;
    align-self: center;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
  }

  .order-cell .order-desc .product-cv {
    grid-row-start: 2;
    grid-row-end: 3;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    align-self: center
  }

  .order-cell .order-desc .product-quantity {
    grid-row-start: span 2;
    grid-column-start: 2;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    align-self: center
  }

  .order-desc.condensed {
    grid-template-columns: 2fr 1fr
  }

  .order-desc.condensed .product-title {
    grid-row-start: 1;
    grid-row-end: 2
  }

  .order-desc.condensed .product-price {
    grid-row-start: 1;
    grid-column-start: 2;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    font-size: 16px
  }

  .order-desc.condensed .product-cv {
    grid-row-start: 2;
    grid-row-end: 3;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
  }

  .order-desc.condensed .product-quantity {
    grid-row-start: 2;
    grid-column-start: 2;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4
  }

  .table-header {
    display: grid
  }
}

@media (min-width:650px) {

  .site-nav .mobile-wrapper li.has-submenu.sub-right:focus-within>.sub-menu,
  .site-nav .mobile-wrapper li.has-submenu.sub-right:hover>.sub-menu {
    right: inherit;
    left: inherit
  }

  .site-nav .mobile-wrapper li.has-submenu:focus-within>.sub-menu,
  .site-nav .mobile-wrapper li.has-submenu:hover>.sub-menu {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    left: 0;
    opacity: 1;
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear;
    overflow: visible
  }
}

@media (min-width:768px) {
  .site-nav.expand {
    padding-bottom: 20px
  }

  .site-nav {
    position: relative;
    width: 100%;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
  }

  .site-nav .nav-logo {
    max-width: 120px
  }

  .site-nav ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  .site-nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1
  }

  .site-nav .user-info img {
    width: 45px
  }

  .site-nav .user-info .user-info-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
  }

  nav.app {
    position: relative;
    padding: 20px;
    width: 100%;
    -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
    box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
  }

  nav ul {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
  }

  nav ul .link-login {
    width: 100%;
    height: 100%;
    opacity: 1
  }

  nav ul img {
    width: 100px
  }

  .logo img {
    content: url(../assets/svg/logo.svg) !important;
    min-width: 160px
  }

  body {
    background-color: #f3f8fc
  }

  .background-wrapper {
    background: url(../assets/images/somaderm-waterline.jpg) top;
    background-size: cover;
    background-attachment: fixed
  }

  .background-wrapper:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: hsla(0, 0%, 100%, .5);
    z-index: 0
  }

  .page {
    margin: 40px;
    width: 90%;
    max-width: 800px;
    border-radius: 17px;
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .2);
    box-shadow: 0 0 25px rgba(0, 0, 0, .2);
    background-color: #fff;
    padding-bottom: 20px
  }

  .main-content {
    padding: 20px 40px 60px
  }

  .step-progress-container {
    border-radius: 17px 17px 0 0;
    z-index: 10
  }

  .step-progress-container .back-button,
  .step-progress-container .next-button {
    position: relative;
    bottom: auto
  }

  .page-actions {
    border-radius: 0 0 17px 17px;
    position: sticky;
    padding: 20px 40px;
    background: hsla(0, 0%, 100%, .6);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fff), to(hsla(0, 0%, 100%, 0)));
    background: linear-gradient(0deg, #fff 20%, hsla(0, 0%, 100%, 0))
  }

  .main-content.welcome-screen,
  .welcome-screen .main-content {
    padding-top: 40px
  }

  .credit-grid {
    grid-template-columns: auto 1fr
  }

  .credits .quick-input {
    border-bottom: 0
  }

  .ca-total {
    color: #7ab800
  }

  .review-screen .text-definition {
    right: 0;
    left: auto
  }

  .order-summary .total-review-summary p {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 75%;
    margin-left: auto
  }

  .order-summary .total-review-summary p>span:first-of-type {
    text-align: left;
    width: 70%
  }

  .global-alert {
    right: 40px;
    top: auto
  }

  .green-alert {
    width: 50%;
    margin: 20px 0 0 auto
  }

  .info-tooltip-wrapper .info-button {
    background: #fff
  }
}

@media (min-width:768px) {
  .menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  li.logo {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    padding-top: 10px
  }

  .item.button {
    width: auto;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    display: block
  }

  .item.cent {
    text-align: -webkit-center
  }

  .toggle {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: right;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    place-content: flex-end
  }

  .item {
    display: none
  }

  .menu li.button a {
    padding: 10px 15px;
    margin: 5px 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content
  }

  .button a {
    background: #fff
  }

  .button.secondary {
    border: 0
  }

  .button.secondary a {
    background: transparent
  }

  .button a:hover {
    text-decoration: none
  }

  .button:not(.secondary) a:hover {
    background: grey;
    border-color: grey
  }

  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
}

@media (min-width:960px) {

  .site-nav a,
  .toggle-ul {
    display: none !important
  }
}

@media (min-width:960px) {
  .menu {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background: none
  }

  .logo {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    max-width: 300px
  }

  li.item {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: relative;
    display: none;
    width: auto;
    border-top: 0;
    margin-left: 10px
  }

  .button {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
  }

  .name {
    position: absolute;
    top: 18px;
    left: 45%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .name span {
    -ms-flex-item-align: center;
    align-self: center
  }

  .toggle {
    display: none
  }

  .saveButton {
    display: contents;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto
  }
}

@media (min-width:1024px) {
  .page {
    margin: 60px
  }

  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card.site-nav {
    background-color: #fff;
    padding: 20px;
    z-index: 100;
    position: relative;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .site-nav .nav-logo {
    width: 100%;
    max-width: 100px;
    height: auto
  }

  .site-nav a {
    display: block
  }

  .site-nav ul {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0
  }

  .site-nav ul,
  .site-nav ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .site-nav ul .text-btn {
    font-size: 16px;
    font-weight: 500;
    color: #4d4f53;
    padding: 10px;
    text-transform: none
  }

  .site-nav ul .text-btn:hover {
    color: #7ab800
  }

  .site-nav.expand {
    padding-bottom: 0
  }

  .site-nav .menu-items-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .site-nav .menu-items-wrapper,
  .site-nav .user-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .site-nav .user-info {
    margin-left: 20px;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px
  }

  .site-nav .user-info img {
    width: 40px;
    height: auto;
    border-radius: 50%
  }

  .site-nav .user-info .user-info-text {
    display: none
  }

  .site-nav .user-info .user-name {
    font-weight: 700
  }

  .site-nav .mobile-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    position: relative
  }

  .site-nav .mobile-wrapper>li {
    margin: 0 6px
  }

  .site-nav .mobile-wrapper li {
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .site-nav .mobile-wrapper li:hover>a,
  .site-nav .mobile-wrapper li:hover>label,
  .site-nav .mobile-wrapper li:hover>svg {
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
    color: #7ab800;
    stroke: #7ab800
  }

  .site-nav .mobile-wrapper li label {
    cursor: pointer;
    position: relative
  }

  .site-nav .mobile-wrapper li label:focus {
    outline: none;
    color: #7ab800
  }

  .site-nav .mobile-wrapper li label svg {
    stroke: #707070;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
    top: -2px
  }

  .site-nav .mobile-wrapper li .sub-menu label:after {
    padding-right: 14px
  }

  .site-nav .mobile-wrapper li i {
    font-size: 10px;
    margin-left: 5px
  }

  .site-nav .mobile-wrapper li a {
    padding: 10px 5px;
    color: #4d4f53;
    display: inline-block;
    width: 100%;
    -webkit-transition: color .2s ease;
    transition: color .2s ease
  }

  .site-nav .mobile-wrapper li input[type=checkbox] {
    display: none
  }

  .site-nav .mobile-wrapper li input:checked~label svg {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    stroke: #7ab800 !important;
    top: -2px
  }

  .site-nav .mobile-wrapper li input:checked~.has-submenu.sub-right .sub-menu {
    right: 0;
    left: inherit
  }

  .site-nav .mobile-wrapper li input:checked~.sub-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: inherit;
    right: inherit;
    opacity: 1;
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear;
    overflow: visible
  }

  .site-nav .mobile-wrapper li input:checked~.sub-menu.sub-members-menu {
    right: 0
  }

  .site-nav .mobile-wrapper .sub-menu {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: absolute;
    background-color: #fff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: auto;
    left: -99999px;
    top: 100%;
    opacity: 0;
    -webkit-transition: left 0s .1s, opacity .1s linear;
    transition: left 0s .1s, opacity .1s linear;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, .18);
    box-shadow: 0 0 0 rgba(0, 0, 0, .18);
    overflow: hidden
  }

  .site-nav .mobile-wrapper .sub-menu li {
    padding: 0 20px;
    border-bottom: 1px solid rgba(154, 155, 156, .25);
    white-space: nowrap;
    width: 100%;
    text-align: center
  }

  .site-nav .mobile-wrapper .sub-menu li a {
    padding: 15px
  }

  .site-nav .mobile-wrapper .sub-menu li:last-child {
    border-bottom: 0
  }

  .site-nav .mobile-wrapper.app {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: -8px;
    position: relative
  }

  nav.app ul {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start
  }

  .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .name span {
    -ms-flex-item-align: center;
    align-self: center
  }

  .name img {
    width: 100px
  }

  .toggle {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-top: 10px;
    place-content: flex-end;
    position: absolute;
    top: 20px;
    right: 10px
  }

  .toggle-ul {
    position: relative;
    top: -20px
  }

  .toggle img {
    -ms-flex-item-align: end;
    align-self: flex-end;
    width: 60px
  }

  .item.button {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3
  }

  .item {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    font-size: 20px;
    padding: 20px 0
  }

  .item,
  .item:first-of-type {
    border-top: 1px solid #c4c4c4
  }

  .item.last {
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 40px
  }

  .cents,
  .item.cent {
    text-align: -webkit-center
  }

  .active .item {
    display: block
  }

  .item,
  .saveButton {
    display: none
  }

  .item {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    width: 100%;
    text-align: center
  }

  .item.first {
    margin-top: 20px
  }

  .main-content.app.white {
    background-color: #fff;
    padding-top: 0
  }

  .mpn {
    width: 100%;
    min-height: 100vh;
    text-align: center
  }

  .mpn li {
    border-bottom: 1px solid #d9dae4;
    padding: 24px
  }

  body {
    background-color: #fafafa
  }

  .background-wrapper {
    height: 100%;
    min-height: 100vh;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    position: relative
  }

  .background-wrapper,
  .page {
    width: 100%;
    background-color: #fafafa;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .page {
    margin: 0 auto;
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
    padding-bottom: 40px
  }

  .main-content,
  .page {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .main-content {
    width: 100%;
    padding: 40px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
  }

  .main-content>section:last-child {
    padding-bottom: 0
  }

  .intro-text {
    text-align: center
  }

  .intro-text h1:only-child {
    margin-bottom: 0
  }

  .intro-text p {
    font-size: 18px
  }

  .intro-text p:last-child {
    margin-bottom: 0
  }

  .step-progress-container {
    background: #fff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: static;
    width: 100%
  }

  .step-progress-container .back-button,
  .step-progress-container .next-button {
    position: fixed;
    bottom: 20px;
    z-index: 10
  }

  .step-progress-container .next-button {
    right: 20px
  }

  .step-progress-container .next-button:hover {
    text-decoration: none
  }

  .step-progress-container .back-button {
    left: 20px
  }

  .page-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: sticky;
    width: 100%;
    background: hsla(0, 0%, 98%, .6);
    background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fafafa), to(hsla(0, 0%, 98%, 0)));
    background: linear-gradient(0deg, #fafafa 20%, hsla(0, 0%, 98%, 0));
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7;
    padding: 20px
  }

  .page-actions .back-button,
  .page-actions .next-button {
    position: relative
  }

  .page-actions .next-button:hover {
    text-decoration: none
  }

  .main-content .space--between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin: 0 auto
  }

  .main-content .pos-relative {
    position: relative
  }

  .main-content p.bold {
    font-weight: 600
  }

  .main-content p.light {
    color: #9a9b9c
  }

  .logged-in .card.sponsor-card {
    padding: 0 20px 20px
  }

  .logged-in .card.sponsor-card .sponsor--id {
    padding-top: 15px
  }

  .ach-wrapper .address,
  .credit-wrapper .address {
    margin-left: 38px
  }

  .mr-0 {
    margin-right: 0
  }

  .center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .italic {
    font-style: italic;
    font-weight: 400
  }

  .align-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .align-top .vertical-space {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: space-around;
    -ms-flex-align: space-around;
    align-items: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .credits img {
    display: block;
    margin: 0 auto
  }

  .credits .ca-total {
    font-weight: 600;
    margin-left: 10px
  }

  .credits .quick-input {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(154, 155, 156, .18)
  }

  .credits .quick-input:last-of-type {
    border-bottom: 0
  }

  .credits .quick-input .input-group {
    max-width: 155px
  }

  .credits .quick-input .status-circle-s {
    margin-right: 5px
  }

  .credit-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px 40px;
    justify-items: start
  }

  .credit-grid,
  .space--between {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .space--between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto
  }

  .green {
    color: #7ab800
  }

  .red {
    color: #ba0c2f
  }

  .dark-orange {
    color: #9a3b26
  }

  .dark-green {
    color: #427730
  }

  .font-20 {
    font-size: 20px
  }

  .font-18 {
    font-size: 18px
  }

  .font-14 {
    font-size: 14px
  }

  .w-max-content {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    width: auto
  }

  .flex-start-svg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
  }

  .flex-start-svg svg {
    margin-right: 10px
  }

  .content--footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
  }

  .add-products .card .inner-content {
    padding-bottom: 0
  }

  .card .inner-content .product-quantity-card {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 340px;
    margin: 0 auto;
    border: 3px solid transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
  }

  .card .inner-content .product-quantity-card.active {
    border: 3px solid #7ab800
  }

  .card .inner-content .product-quantity-card .product-info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px
  }

  .card .inner-content .product-quantity-card .product-info-wrapper img {
    width: 140px;
    height: 140px;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 0;
    padding: 0
  }

  .card .inner-content .product-quantity-card .product-info-wrapper .product-info {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    margin-left: 20px
  }

  .card .inner-content .product-quantity-card .product-info-wrapper .product-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #4d4f53;
    margin-bottom: 10px
  }

  .card .inner-content .product-quantity-card .product-info-wrapper ul {
    list-style: none;
    padding: 10px 0;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2
  }

  .card .inner-content .product-quantity-card .product-info-wrapper ul li {
    color: #747679;
    line-height: 20px
  }

  .card .inner-content .product-quantity-card .product-info-wrapper .product-price {
    color: #4d4f53;
    font-size: 20px;
    line-height: 20px
  }

  .card .inner-content .product-quantity-card .card--footer {
    border-top: 1px solid #f2f2f2;
    margin: auto auto 0;
    width: 100%
  }

  .card .inner-content .product-quantity-card .card--footer.space--between span.dark-green,
  .card .inner-content .product-quantity-card .card--footer.space--between span.gray {
    font-size: 14px;
    width: 120px
  }

  .card .inner-content .product-quantity-card .card--footer.space--between span.gray {
    color: rgba(154, 155, 156, .5)
  }

  .flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%
  }

  .total-cost {
    margin-left: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
  }

  .button.blue.ghost {
    background-color: #fff
  }

  .button.blue.ghost:hover {
    background-color: #007ec3;
    border-color: #007ec3
  }

  p.sub-text {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20px
  }

  .order-summary p.light {
    color: #9a9b9c
  }

  .order-summary p.bold {
    color: #4d4f53;
    font-weight: 600
  }

  .order-summary p.red {
    color: #ba0c2f
  }

  .order-summary .border-top-bottom {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed
  }

  .order-summary .double-border-bottom {
    border-bottom: 1px solid #ededed;
    -webkit-box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed;
    box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed
  }

  .order-summary .border-bottom {
    border-bottom: 1px solid #ededed
  }

  .order-summary .padding-10 {
    padding: 10px 0
  }

  .order-summary .total-review-summary p {
    margin-bottom: 0;
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }

  .order-summary .total-review-summary .review-row {
    padding: 20px 0
  }

  .order-summary .total-review-summary .review-row:last-of-type {
    padding: 10px 0;
    margin-bottom: 20px
  }

  .order-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px 0
  }

  .order-cell img {
    -o-object-fit: contain;
    object-fit: contain;
    height: 90px;
    width: 90px;
    margin-right: 15px
  }

  .order-cell .order-desc {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: grid;
    grid-gap: 5px
  }

  .order-cell .order-desc p {
    margin: 0
  }

  .order-cell .order-desc .product-title {
    margin-bottom: 5px;
    font-weight: 500
  }

  .order-cell .order-desc .product-cv {
    color: #9a9b9c;
    margin-bottom: 7px
  }

  .order-cell .order-desc .product-quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }

  .order-cell .order-desc .product-price {
    text-align: left
  }

  .order-cell a {
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content
  }

  .global-alert {
    bottom: 100%;
    position: absolute
  }

  .starter-kit-screen .main-content section.hide {
    padding-bottom: 0
  }

  .review-screen h1 {
    margin-bottom: 0
  }

  .review-screen .strike-through {
    color: #747679
  }

  .green-alert {
    margin: 20px auto 0
  }

  .table-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed
  }

  .table-title p {
    margin-bottom: 10px;
    line-height: 1.2;
    font-weight: 700
  }

  .table-title a,
  .table-title button {
    margin: -10px 0 0 10px;
    padding: 10px
  }

  .table-header {
    display: none;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 0;
    border-bottom: 1px solid #ededed;
    -webkit-box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed;
    box-shadow: 0 4px 0 0 #fff, 0 5px 0 0 #ededed
  }

  .table-header p {
    line-height: 1;
    margin: 0 0 10px
  }

  .card .center p.address-country {
    margin-bottom: 20px
  }

  #section-account-address {
    margin-top: 20px
  }

  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card {
    text-align: center
  }

  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .flex-20 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .flex-20>*,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .flex-20>* {
    margin-right: 0
  }

  #confirm-address-modal .modal-content .center .blue--switch--container {
    min-width: 220px
  }

  .hide {
    height: 0;
    opacity: 0;
    -webkit-transition: visibility .2s linear, opacity .2s linear;
    transition: visibility .2s linear, opacity .2s linear;
    z-index: -2;
    visibility: hidden;
    position: absolute;
    left: -99999px;
    overflow: hidden
  }

  .hide.reveal,
  .reveal {
    height: auto;
    opacity: 1;
    z-index: auto;
    visibility: visible;
    position: static;
    left: auto;
    overflow: initial
  }

  .info-tooltip-wrapper .info-button {
    background-color: #fafafa
  }

  @media (min-width:449px) {
    .card .inner-content .product-quantity-card .product-info-wrapper .product--card--content--inner {
      width: 140px
    }
  }

  @media (min-width:570px) {
    .order-cell img {
      height: 100px;
      width: 100px;
      margin-right: 20px
    }

    .order-cell .order-desc .product-quantity {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-top: 5px
    }

    .order-cell .order-desc .product-price {
      margin-left: auto;
      text-align: right;
      font-size: 18px
    }

    .order-cell .order-desc {
      grid-template-columns: 1fr 1fr
    }

    .order-cell .order-desc .product-cv,
    .order-cell .order-desc .product-title {
      margin-bottom: 0
    }

    .order-cell .order-desc .product-title {
      -ms-flex-item-align: end;
      align-self: end
    }

    .order-cell .order-desc .product-cv {
      -ms-flex-item-align: start;
      align-self: start
    }

    .order-cell .order-desc {
      grid-template-columns: 4fr 2.5fr 3.5fr
    }

    .order-cell .order-desc .product-title {
      grid-row-start: 1;
      grid-row-end: 2
    }

    .order-cell .order-desc .product-price {
      grid-row-start: span 2;
      grid-column-start: 3;
      align-self: center;
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }

    .order-cell .order-desc .product-cv {
      grid-row-start: 2;
      grid-row-end: 3;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      align-self: center
    }

    .order-cell .order-desc .product-quantity {
      grid-row-start: span 2;
      grid-column-start: 2;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3;
      align-self: center
    }

    .order-desc.condensed {
      grid-template-columns: 2fr 1fr
    }

    .order-desc.condensed .product-title {
      grid-row-start: 1;
      grid-row-end: 2
    }

    .order-desc.condensed .product-price {
      grid-row-start: 1;
      grid-column-start: 2;
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
      font-size: 16px
    }

    .order-desc.condensed .product-cv {
      grid-row-start: 2;
      grid-row-end: 3;
      -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
      order: 3
    }

    .order-desc.condensed .product-quantity {
      grid-row-start: 2;
      grid-column-start: 2;
      -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
      order: 4
    }

    .table-header {
      display: grid
    }
  }

  @media (min-width:650px) {

    .site-nav .mobile-wrapper li.has-submenu.sub-right:focus-within>.sub-menu,
    .site-nav .mobile-wrapper li.has-submenu.sub-right:hover>.sub-menu {
      right: inherit;
      left: inherit
    }

    .site-nav .mobile-wrapper li.has-submenu:focus-within>.sub-menu,
    .site-nav .mobile-wrapper li.has-submenu:hover>.sub-menu {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      left: 0;
      opacity: 1;
      -webkit-transition: opacity .1s linear;
      transition: opacity .1s linear;
      overflow: visible
    }
  }

  @media (min-width:768px) {
    .site-nav.expand {
      padding-bottom: 20px
    }

    .site-nav {
      position: relative;
      width: 100%;
      -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
      box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
    }

    .site-nav .nav-logo {
      max-width: 120px
    }

    .site-nav ul {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    .site-nav ul .link-login {
      width: 100%;
      height: 100%;
      opacity: 1
    }

    .site-nav .user-info img {
      width: 45px
    }

    .site-nav .user-info .user-info-text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-left: 10px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto
    }

    nav.app {
      position: relative;
      padding: 20px;
      width: 100%;
      -webkit-box-shadow: 0 4px 14px rgba(0, 0, 0, .15);
      box-shadow: 0 4px 14px rgba(0, 0, 0, .15)
    }

    nav ul {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row
    }

    nav ul .link-login {
      width: 100%;
      height: 100%;
      opacity: 1
    }

    nav ul img {
      width: 100px
    }

    .logo img {
      content: url(../assets/svg/logo.svg) !important;
      min-width: 160px
    }

    body {
      background-color: #f3f8fc
    }

    .background-wrapper {
      background: url(../assets/images/somaderm-waterline.jpg) top;
      background-size: cover;
      background-attachment: fixed
    }

    .background-wrapper:before {
      content: "";
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: hsla(0, 0%, 100%, .5);
      z-index: 0
    }

    .page {
      margin: 40px;
      width: 90%;
      max-width: 800px;
      border-radius: 17px;
      -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, .2);
      box-shadow: 0 0 25px rgba(0, 0, 0, .2);
      background-color: #fff;
      padding-bottom: 20px
    }

    .main-content {
      padding: 20px 40px 60px
    }

    .step-progress-container {
      border-radius: 17px 17px 0 0;
      z-index: 10
    }

    .step-progress-container .back-button,
    .step-progress-container .next-button {
      position: relative;
      bottom: auto
    }

    .page-actions {
      border-radius: 0 0 17px 17px;
      position: sticky;
      padding: 20px 40px;
      background: hsla(0, 0%, 100%, .6);
      background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fff), to(hsla(0, 0%, 100%, 0)));
      background: linear-gradient(0deg, #fff 20%, hsla(0, 0%, 100%, 0))
    }

    .main-content.welcome-screen,
    .welcome-screen .main-content {
      padding-top: 40px
    }

    .credit-grid {
      grid-template-columns: auto 1fr
    }

    .credits .quick-input {
      border-bottom: 0
    }

    .ca-total {
      color: #7ab800
    }

    .review-screen .text-definition {
      right: 0;
      left: auto
    }

    .order-summary .total-review-summary p {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 75%;
      margin-left: auto
    }

    .order-summary .total-review-summary p>span:first-of-type {
      text-align: left;
      width: 70%
    }

    .global-alert {
      right: 40px;
      top: auto
    }

    .green-alert {
      width: 50%;
      margin: 20px 0 0 auto
    }

    .info-tooltip-wrapper .info-button {
      background: #fff
    }
  }

  @media (min-width:768px) {
    .menu {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center
    }

    li.logo {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      -webkit-box-pack: left;
      -ms-flex-pack: left;
      justify-content: left;
      padding-top: 10px
    }

    .item.button {
      width: auto;
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      display: block
    }

    .item.cent {
      text-align: -webkit-center
    }

    .toggle {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: right;
      -webkit-box-pack: right;
      -ms-flex-pack: right;
      justify-content: right;
      place-content: flex-end
    }

    .item {
      display: none
    }

    .menu li.button a {
      padding: 10px 15px;
      margin: 5px 0;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content
    }

    .button a {
      background: #fff
    }

    .button.secondary {
      border: 0
    }

    .button.secondary a {
      background: transparent
    }

    .button a:hover {
      text-decoration: none
    }

    .button:not(.secondary) a:hover {
      background: grey;
      border-color: grey
    }

    .name {
      position: absolute;
      top: 18px;
      left: 45%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex
    }
  }

  @media (min-width:960px) {

    .site-nav a,
    .toggle-ul {
      display: none !important
    }
  }

  @media (min-width:960px) {
    .menu {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      background: none
    }

    .logo {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
      max-width: 300px
    }

    li.item {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      position: relative;
      display: none;
      width: auto;
      border-top: 0;
      margin-left: 10px
    }

    .button {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2
    }

    .name {
      position: absolute;
      top: 18px;
      left: 45%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex
    }

    .name span {
      -ms-flex-item-align: center;
      align-self: center
    }

    .toggle {
      display: none
    }

    .saveButton {
      display: contents;
      -webkit-box-flex: 1;
      -ms-flex: auto;
      flex: auto
    }
  }

  @media (min-width:1024px) {
    .page {
      margin: 60px
    }

    .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info .product-title,
    .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info strong,
    .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info ul,
    .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info .product-title,
    .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info strong,
    .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info ul {
      text-align: center
    }

    .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info ul li,
    .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info ul li {
      max-width: 100%
    }
  }

  @media (max-width:768px) {
    nav.app {
      border-bottom: 1px solid #747679
    }

    .name {
      position: inherit;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: auto;
      flex-basis: auto;
      font-size: 18px
    }

    .menu,
    .site-nav ul.menu {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between
    }

    .page.app {
      max-width: 1240px
    }

    .main-content {
      padding: 20px 40px 60px
    }
  }

  @media (max-width:768px) {
    .logo {
      display: none
    }
  }

  @media (max-width:649px) {

    .modal .modal--starter-kit-wrapper .grid.tab-card,
    .modal button.accordion.is-open+.accordion-content .grid.tab-card {
      grid-template-columns: 1fr
    }
  }

  @media screen and (max-width:600px) {
    .site-nav .burger-menu a:not(:first-child) {
      display: none
    }

    .site-nav .burger-menu a.icon {
      float: right;
      display: block
    }

    .site-nav .burger-menu.responsive {
      position: relative
    }

    .site-nav .burger-menu.responsive .icon {
      position: absolute;
      right: 0;
      top: 0
    }

    .site-nav .burger-menu.responsive a {
      float: none;
      display: block;
      text-align: left
    }
  }

  @media (max-width:449px) {
    .modal .card .inner-content .product--small .product--card--content {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
    }

    .modal .card .inner-content .product--small .product--card--content .product--card--content--inner {
      text-align: center
    }

    .modal .card .inner-content .product--small .product--card--content img {
      margin-bottom: 20px
    }
  }

  @media (max-width:374px) {
    .credit-grid {
      justify-items: center
    }

    .credits .quick-input {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column
    }

    .credits .quick-input .input-group {
      max-width: 100%
    }

    .quick-input .quick-input-success {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 10px 0
    }
  }

  .product-info .product-title,
  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info strong,
  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info ul,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info .product-title,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info strong,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info ul {
    text-align: center
  }

  .modal .modal--starter-kit-wrapper .grid.tab-card .product--card .product-info ul li,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card .product--card .product-info ul li {
    max-width: 100%
  }
}

@media (max-width:768px) {
  nav.app {
    border-bottom: 1px solid #747679
  }

  .name {
    position: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    font-size: 18px
  }

  .menu,
  .site-nav ul.menu {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .page.app {
    max-width: 1240px
  }

  .main-content {
    padding: 20px 40px 60px
  }
}

@media (max-width:768px) {
  .logo {
    display: none
  }
}

@media (max-width:649px) {

  .modal .modal--starter-kit-wrapper .grid.tab-card,
  .modal button.accordion.is-open+.accordion-content .grid.tab-card {
    grid-template-columns: 1fr
  }
}

@media screen and (max-width:600px) {
  .site-nav .burger-menu a:not(:first-child) {
    display: none
  }

  .site-nav .burger-menu a.icon {
    float: right;
    display: block
  }

  .site-nav .burger-menu.responsive {
    position: relative
  }

  .site-nav .burger-menu.responsive .icon {
    position: absolute;
    right: 0;
    top: 0
  }

  .site-nav .burger-menu.responsive a {
    float: none;
    display: block;
    text-align: left
  }
}

@media (max-width:449px) {
  .modal .card .inner-content .product--small .product--card--content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .modal .card .inner-content .product--small .product--card--content .product--card--content--inner {
    text-align: center
  }

  .modal .card .inner-content .product--small .product--card--content img {
    margin-bottom: 20px
  }
}

@media (max-width:374px) {
  .credit-grid {
    justify-items: center
  }

  .credits .quick-input {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .credits .quick-input .input-group {
    max-width: 100%
  }

  .quick-input .quick-input-success {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px 0
  }
}

/** Used to define container behavior: width, position: fixed etc... **/
/* .Toastify__toast-container {} */

/** Used to define the position of the ToastContainer **/
/* .Toastify__toast-container--top-left {}
.Toastify__toast-container--top-center {}
.Toastify__toast-container--top-right {}
.Toastify__toast-container--bottom-left {}
.Toastify__toast-container--bottom-center {}
.Toastify__toast-container--bottom-right {} */

/** Classes for the displayed toast **/
/* .Toastify__toast--rtl {}
.Toastify__toast--dark {} */
.Toastify__toast {
  border-radius: 5px;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background: #E7F2FE;
  color: #003B5E;
}

.Toastify__toast-theme--colored.Toastify__toast--default .Toastify__close-button {
  color: #003B5E;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #E7F2FE;
  color: #003B5E;
}

.Toastify__toast-theme--colored.Toastify__toast--info .Toastify__close-button {
  color: #003B5E;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #E8F4D7;
  color: #4A772B;
}

.Toastify__toast-theme--colored.Toastify__toast--success .Toastify__close-button {
  color: #4A772B;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #FCEADE;
  color: #9A3B26;
}

.Toastify__toast-theme--colored.Toastify__toast--warning .Toastify__close-button {
  color: #9A3B26;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #F6E4EC;
  color: #782434;
}

.Toastify__toast-theme--colored.Toastify__toast--error .Toastify__close-button {
  color: #782434;
}

/* .Toastify__toast-theme--colored.Toastify__toast-body {} */

/** Classes for the close button. Better use your own closeButton **/
/* .Toastify__close-button {}
.Toastify__close-button--default {}
.Toastify__close-button>svg {}
.Toastify__close-button:hover, .Toastify__close-button:focus {} */

/** Classes for the progress bar **/
/* .Toastify__progress-bar {}
.Toastify__progress-bar--animated {}
.Toastify__progress-bar--controlled {}
.Toastify__progress-bar--rtl {}
.Toastify__progress-bar--default {}
.Toastify__progress-bar--dark {} */